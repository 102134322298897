.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.customDialog {
  width: 1000px !important; 
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* @font-face {
  font-family: "DG_Ghayaty";   
  src: local('DG-Ghayaty-Regular-F.ttf'), url('./assets/fonts/DG_Ghayaty_Regular_F.ttf') format('truetype');} */

@font-face {
    font-family: "FrutigerLTArabic45Light";   
    src: local('FrutigerLTArabic45Light.ttf'), url('./assets/fonts/FrutigerLTArabic45Light.ttf') format('truetype');}
  
  

