.timer-component {
  
    font-family: 'Arial', sans-serif;
    max-width: 800px;
    min-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .input-section {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  
  }
  
  .add-icon {
    cursor: pointer;
    margin-right: 10px;
    color: #3f51b5;
    transition: color 0.3s;
  }
  
  .add-icon:hover {
    color: #303f9f;
  }
  
  .property-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    transition: border-color 0.3s;
  }
  
  .property-input:focus {
    border-color: #3f51b5;
    outline: none;
  }
  
  .property-section {
    margin-bottom: 20px;
  }
  
  .property-label {
    margin: 0 0 10px;
    font-size: 18px;
    color: #333;
    text-align: right;
  }
  
  .save-button {
    padding: px 20px;
    background: #fcc318;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;
    width: 30%;
    margin-right: auto;
    margin-left: auto;
    
  }
  
  .save-button:hover {
    background: #fcc377;
  }
  