.radio-button-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin:10px;
  }
  
  .radio-label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    padding-left: 30px;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
    margin:10px;
  }
  
  .radio-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .radio-custom {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #f1f1f1;
    border: 2px solid #ccc;
    border-radius: 50%;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .radio-label:hover .radio-custom {
    background-color: #e0e0e0;
  }
  
  .radio-input:checked ~ .radio-custom {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .radio-custom::after {
    content: '';
    position: absolute;
    display: none;
  }
  
  .radio-input:checked ~ .radio-custom::after {
    display: block;
  }
  
  .radio-label .radio-custom::after {
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
    transform: translate(-50%, -50%);
  }
  